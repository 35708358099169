import React, { ReactElement, useRef, useEffect, useState } from 'react'; 
import "./style.css"
import { useLocation } from '@reach/router';

export const CWScripts = (): ReactElement => {
 
  const location = useLocation();
 
  useEffect(()=>{
    function addContent() {
      var orderLink = document.querySelector('#order-now-link').getAttribute('href');
      var stickyBarHTML = `
        <div class="cw_sw_1_sticky_bar">
          <div class="cw_sw_1_sticky_bar_container">
            <div class="cw_sw_1_flex_container">
              <div class="cw_sw_1_flex_content">
                <div class="cw_sw_1_mn_heading">Get SketchWow for a 1-Time Price</div>
                <div class="cw_sw_1_mn_content">ZERO MONTHLY / ANNUAL FEES FOREVER</div>
              </div>
              <div class="cw_sw_1_mn_cta">
                <a href="${orderLink}">Order Now</a>
              </div>
            </div>
          </div>
        </div>
      `;
      document.querySelector('main').insertAdjacentHTML('afterend', stickyBarHTML);
    }
  
    var observer = new MutationObserver(function(mutations, me) {
      if (document.querySelector('main')) {
       setTimeout(addContent, 1000);
        me.disconnect();
      }
    });
  
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  
    window.addEventListener('scroll', function() {
      var stickyBar = document.querySelector('.cw_sw_1_sticky_bar');
      if (stickyBar) {
        if (window.scrollY >= 1000) {
          document.body.classList.add('scroll_active');
          stickyBar.classList.add('sticky_active');
        } else {
          document.body.classList.remove('scroll_active');
          stickyBar.classList.remove('sticky_active');
        }

        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
          document.body.classList.remove('scroll_active');
          stickyBar.classList.remove('sticky_active');
        } 
      }
    });



    let link = "https://sketchwow.com/pricing";
    if(location.pathname == '/sale/' || location.pathname == '/sale')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sl"; 
    else if(location.pathname == '/save/' || location.pathname == '/save')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sv";
    else if(location.pathname == '/offer/' || location.pathname == '/offer')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=of";
    else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=lt";
    else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=ltd";
    else if(location.pathname == '/deal/' || location.pathname == '/deal')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=dl";
    else if((location.pathname == '/promo/' || location.pathname == '/promo') && localStorage.getItem('refid'))
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=pr&coupon_code=save10percent&ref="+localStorage.getItem('refid');
    else if(location.pathname == '/promo/' || location.pathname == '/promo')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=pr";
 

    var CWTargetElement = document.querySelector(".e1rrpdhi14");
    CWTargetElement.insertAdjacentHTML('beforebegin', `<section class="cw-container-sketch css-1l5b2id e1rrpdhi12">
    <div class="css-5fl59n ekes0c03">
    <div class="cw-sketch-new-section" >
          <div class="cw-heading-sketch">
            <h2 class="css-4pv62i e1rrpdhi9">Competitors <small>vs</small> <span class="bg-color-addimg">SketchWow</span></h2>
          </div>
                <div class="cw-main-two-section">
                <div class="cw-bg1-section">
                    <div class="bg1heading css-4pv62i e1rrpdhi9">Typical Software</div>
                    <ul class="cw-list-sketech">
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Overused "too-perfect-looking"
                            templates</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Difficult to learn & navigate</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Makes 2,000 types of designs (99%
                                you'll never use)</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Makes boring, forgettable designs</li>

                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Typical user experience</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Expensive monthly (recurring) fees</li>
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/Close.png" alt=""></span> <span
                                class="cw-text-sketch"></span>Limited AI features & visuals</li>
                    </ul>
                </div>
                <div class="cw-sectionvs">Vs</div>
                <div class="cw-bg2-section">
                    <div class="bg2-img">
                        <img src="/cwfiles/sketch-logo.png" alt="">
                    </div>
                    <ul class="cw-list-sketech">
                        <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Eye-catching templates unlike anything
                            you've seen</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Simple to navigate and use</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Designed for making clean, diagrams,
                            workflows and visuals</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Creates unique, hand-drawn style visuals</li>

                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>"The most fun drag & drop diagram maker"</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Only $49, one time</li>
                    <li class="cw-sketch-data "><span class="cw-img-wrong"><img src="/cwfiles/check-light.png" alt=""></span> <span
                            class="cw-text-sketch"></span>Many AI-powered options: mindmaps,
                            workflows, visual summaries, infographics</li>
                    </ul>
                </div>
            </div>
            <div class="cw-button-start">
                <a href="`+link+`" class="css-10gfo7v exn679h2">Get SketchWow</a>
            </div>
        </div>
    </div>
    </section>`);


}, []);

 

  return (
    <>  </>
            
  );

  
};
